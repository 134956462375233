@import url('https://fonts.googleapis.com/css?family=Catamaran');

h1 {
  margin: 0 0 50px;
  font-weight: normal;
  color: #fff;
  transition: all .3s ease;
  text-align: center;
}
h1 a {color:#492059;}
.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1230px;
  background: rgba(255,255,255,0);
  flex-direction: row;
}
h2 {
  width: 50%;
  text-align: center;
  height: 44px;
  line-height: 24px;
  font-weight: 400;
  
}
@media screen and (max-width: 768px) {
  h2 {
    width: 100%;
  }
  h1 {
    margin-bottom: 15px;
    line-height: 30px;
  }
}

a, a > span {
  position: relative;
  color: inherit;
  text-decoration: none;
  /* line-height: 24px; */
}
a:before, a:after, a > span:before, a > span:after {
  content: '';
  position: absolute;
  transition: transform .5s ease;
}


.hover-1 {
  padding-top: 10px;
}
.hover-1:before {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: #492059;
  transform: scaleX(0);
}
.hover-1:hover:before {
  transform: scaleX(1);
}
.hover-2 {
  padding: 10px;
}
.hover-2:before, .hover-2:after {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-style: solid;
  border-color: #492059;
}
.hover-2:before {
  border-width: 2px 0 2px 0;
  transform: scaleX(0);
}
.hover-2:after {
  border-width: 0 2px 0 2px;
  transform: scaleY(0);
}
.hover-2:hover:before, .hover-2:hover:after {
  transform: scale(1, 1);
}
.hover-3 {
  display: inline-flex;
  padding-top: 10px;
  padding-bottom: 5px;
  overflow: hidden;
}
.hover-3:before {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: #492059;
  transform: translateX(-100%);
}
.hover-3:hover:before {
  transform: translateX(0);
}
.hover-4 {
  padding: 10px;
  overflow: hidden;
  text-align: center;
}
.hover-4:before, .hover-4:after {
  left: 0;
  width: 100%;
  height: 2px;
  background: #492059;
  text-align: center;
}
.hover-4:before {
  bottom: 0;
  transform: translateX(-100%);
  text-align: center;
}
.hover-4:after {
  top: 0;
  transform: translateX(100%);
  text-align: center;
}
.hover-4:hover:before, .hover-4:hover:after {
  transform: translateX(0);
  text-align: center;
}
.hover-5 {
  display: inline-block;
  overflow: hidden;
}
.hover-5:before, .hover-5:after {
  right: 0;
  bottom: 0;
  background: #492059;
}
.hover-5:before {
  width: 100%;
  height: 2px;
  transform: translateX(-100%);
}
.hover-5:after {
  width: 2px;
  height: 100%;
  transform: translateY(100%);
}
.hover-5 > span {
  display: flex;
  padding: 10px;
}
.hover-5 > span:before, .hover-5 > span:after {
  left: 0;
  top: 0;
  background: #492059;
}
.hover-5 > span:before {
  width: 100%;
  height: 2px;
  transform: translateX(100%);
}
.hover-5 > span:after {
  width: 2px;
  height: 100%;
  transform: translateY(-100%);
}
.hover-5:hover:before, .hover-5:hover:after, .hover-5:hover > span:before, .hover-5:hover > span:after {
  transform: translate(0, 0);
}
.hover-6 {
  display: inline-flex;
  overflow: hidden;
}
.hover-6:before, .hover-6:after {
  right: 0;
  bottom: 0;
  background: #492059;
  transition: transform .3s ease;
}
.hover-6:before {
  width: 100%;
  height: 2px;
  transform: translateX(-100%);
  transition-delay: .9s;
}
.hover-6:after {
  width: 2px;
  height: 100%;
  transform: translateY(100%);
  transition-delay: .6s;
}
.hover-6 > span {
  display: flex;
  padding: 10px;
}
.hover-6 > span:before, .hover-6 > span:after {
  left: 0;
  top: 0;
  background: #492059;
  transition: transform .3s ease;
}
.hover-6 > span:before {
  width: 100%;
  height: 2px;
  transform: translateX(100%);
  transition-delay: .3s;
}
.hover-6 > span:after {
  width: 2px;
  height: 100%;
  transform: translateY(-100%);
  transition-delay: 0s;
}
.hover-6:hover:before, .hover-6:hover:after, .hover-6:hover > span:before, .hover-6:hover > span:after {
  transform: translate(0, 0);
}
.hover-6:hover:before {
  transition-delay: 0s;
}
.hover-6:hover:after {
  transition-delay: .3s;
}
.hover-6:hover > span:before {
  transition-delay: .6s;
}
.hover-6:hover > span:after {
  transition-delay: .9s;
}
.hover-7 {
  display: inline-block;
  overflow: hidden;
}
.hover-7:before {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #492059;
  transform: translateX(-100%);
}
.hover-7:after {
  content: attr(data-content);
  left: 0;
  top: 0;
  width: 0;
  margin: 12px;
  color: #492059;
  white-space: nowrap;
  overflow: hidden;
  transition: width .5s ease;
}
.hover-7 > span {
  display: block;
  margin: 2px;
  padding: 10px;
  background: #9556AE;
}
.hover-7:hover:before {
  transform: translateX(0);
}
.hover-7:hover:after {
  width: calc(100% - 24px);
}
.hover-8 {
  display: inline-flex;
  overflow: hidden;
}
.hover-8:before {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #492059;
  transform: translateY(-100%);
}
.hover-8:after {
  content: attr(data-content);
  top: 0;
  left: 0;
  height: 0;
  margin: 12px;
  color: #492059;
  white-space: nowrap;
  overflow: hidden;
  transition: height .5s ease;
}
.hover-8 > span {
  display: block;
  margin: 2px;
  padding: 10px;
  background: #9556ae;
}
.hover-8:hover:before {
  transform: translateY(0);
}
.hover-8:hover:after {
  height: calc(100% - 24px);
}
.footer {text-align: center;}
.footer a {color:#492059;}

