.window {
    /* transform: rotate3d(1,1,1, -14deg) */
}



.content {
    position: absolute;
    left: -43px;
    top: -36px;
    width: 81px;
    height: 81px;
    border-radius: 3px;
    overflow-y: auto;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transform: translate3d(0,0,1px);
    -moz-transform: translate3d(0,0,1px);
     -ms-transform: translate3d(0,0,1px);
      -o-transform: translate3d(0,0,1px);
         transform: translate3d(0,0,1px);
  }

  .content2 {
    position: absolute;
    left: -41px;
    top: -40px;
    width: 81.5px;
    height: 81px;
    border-radius: 3px;
    overflow-y: auto;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transform: translate3d(0,0,1px);
    -moz-transform: translate3d(0,0,1px);
     -ms-transform: translate3d(0,0,1px);
      -o-transform: translate3d(0,0,1px);
         transform: translate3d(0,0,1px);
  }

  .contentArticles {
    position: absolute;
    /* left: -50px;
    top: -60px; */
    left: -3rem;
    top: -4.5rem;
    width: 100px;
    height: 50px;
    border-radius: 3px;
    overflow-y: auto;
    padding: 0;
    /* border: solid red 1px; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transform: translate3d(0,0,1px);
    -moz-transform: translate3d(0,0,1px);
     -ms-transform: translate3d(0,0,1px);
      -o-transform: translate3d(0,0,1px);
         transform: translate3d(0,0,1px);
  }
  
  .wrapper {
    padding: 2px;
    width: 100%;
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transform: translate3d(0,0,1px);
    -moz-transform: translate3d(0,0,1px);
     -ms-transform: translate3d(0,0,1px);
      -o-transform: translate3d(0,0,1px);
         transform: translate3d(0,0,1px);
  }

  .wrapper2 {
    padding-left: 7px;
    padding-top: 10px;
    width: 100%;
    height: 80%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transform: translate3d(0,0,1px);
    -moz-transform: translate3d(0,0,1px);
     -ms-transform: translate3d(0,0,1px);
      -o-transform: translate3d(0,0,1px);
         transform: translate3d(0,0,1px);
  }

  .wrapper3 {
    padding-left: 1px;
    padding-top: 2px;
    width: 100%;
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transform: translate3d(0,0,1px);
    -moz-transform: translate3d(0,0,1px);
     -ms-transform: translate3d(0,0,1px);
      -o-transform: translate3d(0,0,1px);
         transform: translate3d(0,0,1px);
  }

  .wrapperArticles {
    padding-left: 1px;
    padding-top: 2px;
    width: 100%;
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transform: translate3d(0,0,1px);
    -moz-transform: translate3d(0,0,1px);
     -ms-transform: translate3d(0,0,1px);
      -o-transform: translate3d(0,0,1px);
         transform: translate3d(0,0,1px);
  }

  .texthead {
    font-family: "Helvetica Neue";
    font-weight: normal;
    font-style: italic;
    font-size: 5px;
    color: #faf8f0;
    text-align: center;
    margin-bottom: 3px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transform: translate3d(0,0,1px);
    -moz-transform: translate3d(0,0,1px);
     -ms-transform: translate3d(0,0,1px);
      -o-transform: translate3d(0,0,1px);
         transform: translate3d(0,0,1px);
  }

  .texthead2 {
    font-family: "Helvetica Neue";
    font-weight: normal;
    font-style: italic;
    font-size: 4px;
    color: #faf8f0;
    text-align: center;
    margin-bottom: 3px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transform: translate3d(0,0,1px);
    -moz-transform: translate3d(0,0,1px);
     -ms-transform: translate3d(0,0,1px);
      -o-transform: translate3d(0,0,1px);
         transform: translate3d(0,0,1px);
  }

  .texthead3 {
    font-family: "Helvetica Neue";
    font-weight: 400;
    font-style: normal;
    font-size: 5px;
    color: #24292d;
    text-align: center;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transform: translate3d(0,0,1px);
    -moz-transform: translate3d(0,0,1px);
     -ms-transform: translate3d(0,0,1px);
      -o-transform: translate3d(0,0,1px);
         transform: translate3d(0,0,1px);
  }

  .texthead4 {
    font-family: "Helvetica Neue";
    font-weight: 400;
    font-style: normal;
    font-size: 10px;
    color: #24292d;
    text-align: center;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transform: translate3d(0,0,1px);
    -moz-transform: translate3d(0,0,1px);
     -ms-transform: translate3d(0,0,1px);
      -o-transform: translate3d(0,0,1px);
         transform: translate3d(0,0,1px);
  }

  .texthead5 {
    font-family: "Helvetica Neue";
    font-weight: 400;
    font-style: normal;
    font-size: 40px;
    color: #24292d;
    text-align: center;
    margin-top: 50px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transform: translate3d(0,0,1px);
    -moz-transform: translate3d(0,0,1px);
     -ms-transform: translate3d(0,0,1px);
      -o-transform: translate3d(0,0,1px);
         transform: translate3d(0,0,1px);
  }

  .texthead6 {
    font-family: "Helvetica Neue";
    font-weight: 400;
    font-style: normal;
    font-size: 10px;
    color: #24292d;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 20px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transform: translate3d(0,0,1px);
    -moz-transform: translate3d(0,0,1px);
     -ms-transform: translate3d(0,0,1px);
      -o-transform: translate3d(0,0,1px);
         transform: translate3d(0,0,1px);
  }

  .texthead7 {
    font-family: "Helvetica Neue";
    font-weight: 300;
    font-style: normal;
    font-size: 5px;
    color: #24292d;
    text-align: center;
    margin-bottom: 2px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transform: translate3d(0,0,1px);
    -moz-transform: translate3d(0,0,1px);
     -ms-transform: translate3d(0,0,1px);
      -o-transform: translate3d(0,0,1px);
         transform: translate3d(0,0,1px);
  }

  .texthead8 {
    font-family: "Helvetica Neue";
    font-weight: 300;
    font-style: italic;
    font-size: 4px;
    color: #24292d;
    text-align: center;
    margin-bottom: 3px;
    text-decoration: underline;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transform: translate3d(0,0,1px);
    -moz-transform: translate3d(0,0,1px);
     -ms-transform: translate3d(0,0,1px);
      -o-transform: translate3d(0,0,1px);
         transform: translate3d(0,0,1px);
  }

  .texthead9 {
    font-family: "Helvetica Neue";
    font-weight: 300;
    font-style: italic;
    font-size: 4px;
    color: #24292d;
    text-align: center;
    margin-bottom: 3px;
    text-decoration: underline;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transform: translate3d(0,0,1px);
    -moz-transform: translate3d(0,0,1px);
     -ms-transform: translate3d(0,0,1px);
      -o-transform: translate3d(0,0,1px);
         transform: translate3d(0,0,1px);
  }


  .boxArticles {
    width: 6em;
    background: rgba(70,70,73,.3);
    border-radius: 5px;
    border: solid white 1px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transform: translate3d(0,0,1px);
    -moz-transform: translate3d(0,0,1px);
     -ms-transform: translate3d(0,0,1px);
      -o-transform: translate3d(0,0,1px);
         transform: translate3d(0,0,1px);
  }


  .boxAboutMe {
    width: 6em;
    background: rgba(70,70,73,.3);
    border-radius: 5px;
    border: solid white 1px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transform: translate3d(0,0,1px);
    -moz-transform: translate3d(0,0,1px);
     -ms-transform: translate3d(0,0,1px);
      -o-transform: translate3d(0,0,1px);
         transform: translate3d(0,0,1px);
  }

  .boxProjects {
    width: 6em;
    background: rgba(70,70,73,.3);
    border-radius: 5px;
    border: solid white 1px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transform: translate3d(0,0,1px);
    -moz-transform: translate3d(0,0,1px);
     -ms-transform: translate3d(0,0,1px);
      -o-transform: translate3d(0,0,1px);
         transform: translate3d(0,0,1px);
  }

  .textheadArticles {
    width: 6em;
    opacity: 100%;
    /* background: #464649; */
    border-radius: 5px;
    font-family: "Helvetica Neue";
    font-weight: 400;
    font-style: italic;
    font-size: 1rem;
    color: white;
    text-align: center;
    margin-bottom: 0px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transform: translate3d(0,0,1px);
    -moz-transform: translate3d(0,0,1px);
     -ms-transform: translate3d(0,0,1px);
      -o-transform: translate3d(0,0,1px);
         transform: translate3d(0,0,1px);
  }

  .textheadAboutMe {
    width: 6em;
    /* background: #464649; */
    border-radius: 5px;
    font-family: "Helvetica Neue";
    font-weight: 400;
    font-style: italic;
    font-size: 1rem;
    color: white;
    text-align: center;
    margin-bottom: 0px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transform: translate3d(0,0,1px);
    -moz-transform: translate3d(0,0,1px);
     -ms-transform: translate3d(0,0,1px);
      -o-transform: translate3d(0,0,1px);
         transform: translate3d(0,0,1px);
  }

  .textheadProjects {
    width: 6em;
    /* background: #464649; */
    border-radius: 5px;
    font-family: "Helvetica Neue";
    font-weight: 400;
    font-style: italic;
    font-size: 1rem;
    color: white;
    text-align: center;
    margin-bottom: 0px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transform: translate3d(0,0,1px);
    -moz-transform: translate3d(0,0,1px);
     -ms-transform: translate3d(0,0,1px);
      -o-transform: translate3d(0,0,1px);
         transform: translate3d(0,0,1px);
  }


  .text {
    font-family: "Helvetica Neue";
    font-weight: 300;
    font-style: normal;
    font-size: 3.5px;
    color: white;
    text-align: justify;
    margin-bottom: 4px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transform: translate3d(0,0,1px);
    -moz-transform: translate3d(0,0,1px);
     -ms-transform: translate3d(0,0,1px);
      -o-transform: translate3d(0,0,1px);
         transform: translate3d(0,0,1px);
  }

  .text2 {
    font-family: "Helvetica Neue";
    font-weight: 300;
    font-style: normal;
    font-size: 3px;
    color: 24292d;
    text-align: justify;
    margin-bottom: 4px;
    margin-left: 3px;
    margin-right: 3px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transform: translate3d(0,0,1px);
    -moz-transform: translate3d(0,0,1px);
     -ms-transform: translate3d(0,0,1px);
      -o-transform: translate3d(0,0,1px);
         transform: translate3d(0,0,1px);
  }


  .socials {
    font-family: "Helvetica Neue";
    font-weight: 300;
    font-style: normal;
    font-size: 3.5px;
    color: white;
    margin-bottom: 4px;
    margin-left: 4px;
    margin-right: 4px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transform: translate3d(0,0,1px);
    -moz-transform: translate3d(0,0,1px);
     -ms-transform: translate3d(0,0,1px);
      -o-transform: translate3d(0,0,1px);
         transform: translate3d(0,0,1px);
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10px;
}

.container2 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3px;
}
  
  ul.cloud {
    list-style: none;
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    /* line-height: 2.75rem; */
    /* width: 450px; */
  }
  
  ul.cloud a {
    /*   
    Not supported by any browser at the moment :(
    --size: attr(data-weight number); 
    */
    --size: 4;
    color: var(--color);
    font-size: calc(var(--size) * 3px);
    display: block;
    padding: 0.1rem;
    position: relative;
    text-decoration: none;
    /* 
    For different tones of a single color
    opacity: calc((15 - (9 - var(--size))) / 15); 
    */
  }
  
  ul.cloud a[data-weight="1"] { --size: 1; --color: #7894a0;}
  ul.cloud a[data-weight="2"] { --size: 1.1; --color: #7894a0;}
  ul.cloud a[data-weight="3"] { --size: 1.2; --color: #436aaa;}
  ul.cloud a[data-weight="4"] { --size: 1.3; --color: #fa8d70;}
  ul.cloud a[data-weight="5"] { --size: 1.5; --color: #dec27d;}
  
  ul[data-show-value] a::after {
    content: " (" attr(data-weight) ")";
    font-size: 1rem;
  }
  
  /* ul.cloud li:nth-child(1n) a { --color: #790101; }
  ul.cloud li:nth-child(2n) a { --color: #dec27d; }
  ul.cloud li:nth-child(3n) a { --color: #436aaa; }
  ul.cloud li:nth-child(4n) a { --color: #7894a0; }
  ul.cloud li:nth-child(5n) a { --color: #edf2f7; } */
  
  ul.cloud a:focus {
    outline: 1px dashed;
  }
  
  ul.cloud a::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 0;
    height: 100%;
    background: var(--color);
    transform: translate(-50%, 0);
    opacity: 0.15;
    transition: width 0.25s;
  }
  
  ul.cloud a:focus::before,
  ul.cloud a:hover::before {
    width: 100%;
  }
  
  @media (prefers-reduced-motion) {
    ul.cloud * {
      transition: none !important;
    }
  }