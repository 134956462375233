
  .bg {
    background: linear-gradient(
      to bottom right,  
      #355993, #ef8545
      ), white;
    background-repeat: no-repeat;
    background-size: 100% 20%;
  }


  
  h1 {
    padding: 0;
    margin: 0;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 100;
  }
      
  @keyframes gradient { 
    0%{background-position:0% 0%}
    50%{background-position:100% 100%}
    100%{background-position:0% 0%}
  }
