:root {
    --blue: #355993;
    --yellow: #ef8545;
    --mix: #240a88;
  }
  
  body,
  html {
    padding: 0;
    margin: 0;
    overflow-x: hidden;
  }
  
  * {
    font-family: "Overpass", sans-serif;
    color: #222;
  }
  
  .mainhead {
    display: inline-block;
    width: 36vw;
    left: 32vw;
    text-align: left;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .mainhead2 {
    display: inline-block;
    width: 36vw;
    left: 32vw;
    text-align: left;
    position: absolute;
    top: 20%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .mainhead3 {
    display: inline-block;
    width: 36vw;
    left: 32vw;
    text-align: left;
    position: absolute;
    top: 100%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  
  i.down {
    border: solid #222;
    border-width: 0 0.4vw 0.4vw 0;
    display: inline-block;
    padding: 0.4vw;
    animation: upInArrow 1s forwards;
    animation-delay: 0.7s;
    opacity: 0;
  }
  
  .mainarrow {
    transition: 0.5s;
    top: 0;
    position: relative;
    animation: arrowMove 2s ease infinite;
    display: block;
  }
  
  @keyframes arrowMove {
    0% {
      transform: translateY(-0.2vw);
    }
    50% {
      transform: translateY(0.5vw);
    }
    100% {
      transform: translateY(-0.2vw);
    }
  }
  
  @keyframes upInArrow {
    from {
      opacity: 0;
      transform: translateY(1vw) rotate(45deg);
    }
    to {
      opacity: 1;
      transform: translateY(0) rotate(45deg);
    }
  }
  
  h1 span {
    animation: upIn 1s forwards;
    opacity: 0;
    display: inline-block;
  }
  
  @keyframes upIn {
    from {
      opacity: 0;
      transform: translateY(1vw);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .cont {
    width: 100vw;
    height: 100vh;
    text-align: center;
    position: relative;
  }
  
  .dotgrid {
    background-image: repeating-linear-gradient(135deg,
    transparent,
    transparent .5vw,
    #222222 .5vw,
    #222222 calc(1.42vw/2));
    background-repeat: repeat;
    background-size: 1vw 1vw;
    width: 5vw;
    height: 5vw;
    position: fixed;
    background-attachment: local;
    background-position: 0 0;
    z-index: 1000;
    left: 16vw;
    top: 58vh;
    opacity: 0;
    transform: rotate(90deg);
    animation: topIn 1.5s forwards;
    mix-blend-mode: multiply;
  }
  
  .dgcirc {
    background: var(--yellow);
    width: 8vw;
    height: 8vw;
    position: fixed;
    background-attachment: local;
    background-position: 0 0;
    z-index: 1000;
    left: 17.5vw;
    top: 55vh;
    border-radius: 50vw;
    margin-top: -1.5vw;
    animation: upIn 1s forwards;
    animation-delay: 0.25s;
    animation-iteration-count: 1;
    opacity: 0;
    mix-blend-mode: multiply;
  }
  
  .dotgridbg {
    background: var(--blue);
    background-repeat: repeat;
    background-size: 1.5vw;
    width: 12vw;
    height: 8vw;
    position: fixed;
    background-attachment: local;
    background-position: 0 0;
    z-index: 1000;
    left: 19.5vw;
    top: 60vh;
    margin-top: 1.5vw;
    animation: upIn 1s forwards;
    animation-delay: 0.15s;
    animation-iteration-count: 1;
    opacity: 0;
  }
  
  .dotgrid:hover {
    background-position: 0 0;
  }
  
  .tshadow, .tshadow2 {
    color: var(--yellow)
  }
  
  .blueeee {
    color: var(--blue) !important;
  }
  
  .arc {
    border: .7vw solid var(--blue);
    border-radius: 50%;
    width: 2vw;
    height: 2vw;
    border-top-color: transparent;
    position: fixed;
    top: 28vh;
    left: 75vw;
    transform: rotate(135deg);
    animation: topIn 1.5s forwards;
    mix-blend-mode: multiply;
  }
  
  @keyframes topIn {
    from {
      opacity: 0;
      margin-top: 1vw;
    }
    to {
      opacity: 1;
      margin-top: 0;
    }
  }
  
  .triangle {
    position: fixed;
    top: 27vh;
    left: 73vw;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4vw 7vw 4vw 0;
    border-color: transparent var(--yellow) transparent transparent;
    mix-blend-mode: multiply;
    animation: topIn 1.5s forwards;
    transform: rotate(30deg) scale(.9);
  }
  
  .linebg {
    background-image: 
      radial-gradient(#222222 20%, transparent 0);
    background-repeat: repeat;
    background-size: 1vw 1vw;
    width: 8vw;
    height: 8vw;
    position: fixed;
    background-attachment: local;
    background-position: 0 0%;
    z-index: 1000;
    left: 76.3vw;
    top: 35vh;
    animation: upIn 1s forwards;
    animation-iteration-count: 1;
    opacity: 0;
    mix-blend-mode: difference;
  }
  
  .btn {
    position: relative;
    left: 0vw;
    top: 1vw;
    font-size: 1.5vw;
    font-weight: 500;
    border: .2vw solid #222;
    padding: .7vw 1vw .5vw 1vw;
    color: #222;
    transition: color .3s, border .3s;
  }
  
  .btn::before {
    content: '';
    width: 104%;
    height: 112%;
    position: absolute;
    bottom: -.65vw;
    left: .3vw;
    background: var(--yellow);
    mix-blend-mode: multiply;
    transition: .3s;
  }
  
  .btn:hover::before {
    bottom: -.2vw;
    left: -.25vw;
    transform: translate(.2vw,-.375);
    opacity: 0;
  }
  
  .btn:hover {
    color: var(--blue);
    border: .2vw solid var(--blue);
  }





  
  .container {
    position: relative;
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 60px;
    background: #222;
  }
  
  .card {
    position: relative;
    width: 25vw;
    height: 35vh;
    background: #2e2e2e;
    display: flex;
    justify-content: center;
    align-items: center;
    --color: #ef8545;
  }
  
  .card::before {
    content: '';
    position: absolute;
    inset: -10px 50px;
    border-top: 4px solid var(--color);
    border-bottom: 4px solid var(--color);
    transform: skewY(5deg);
    transition: 0.5s ease-in-out;
  }
  
  .card:hover::before {
    inset: -10px 40px;
    transform: skewY(0deg);
  }
  
  .card::after {
    content: '';
    position: absolute;
    inset: 60px -10px;
    border-left: 4px solid var(--color);
    border-right: 4px solid var(--color);
    transform: skew(5deg);
    transition: 0.5s ease-in-out;
  }
  
  .card:hover::after {
    inset: 40px -10px;
    transform: skew(0deg);
  }
  
  .card .content {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    background: #2e2e2e;
    gap: 20px;
    overflow: hidden;
    z-index: 10;
  }
  
  .card .content .icon {
    color: var(--color);
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.5rem;
    background: #2e2e2e;
    box-shadow: 0 0 0 4px #2e2e2e,
      0 0 0 6px var(--color);
    transition: 0.5s ease-in-out;
  }
  
  .card:hover .content .icon {
    background: var(--color);
    color: #2e2e2e;
    box-shadow: 0 0 0 4px #2e2e2e,
      0 0 0 300px var(--color);
  }
  
  .card .content .text h3 {
    color: #fff;
    font-size: 1.5rem;
    font-weight: 500;
    transition: 0.5s ease-in-out;
  }
  
  .card:hover .content .text h3 {
    color: #2e2e2e;
  }
  
  .card .content .text p {
    color: #999;
    transition: 0.5s ease-in-out;
  }
  
  .card:hover .content .text p {
    color: #2e2e2e;
  }
  
  .card .content .text a {
    position: relative;
    display: inline-block;
    background: var(--color);
    color: #2e2e2e;
    text-decoration: none;
    font-weight: 500;
    margin-block-start: 10px;
    padding: 8px 15px;
  }
  
  .card:hover .content .text a {
    background: #2e2e2e;
    color: var(--color);
  }

  
  /* columns*/
  
  /* row gaps */
  ul li:not(:last-child) {
    margin-bottom: var(--row-gap);
  }
  
  /* card */
  ul li {
    grid-column: 2;
    --inlineP: 2.5rem;
    margin-inline: var(--inlineP);
    grid-row: span 2;
    display: grid;
    grid-template-rows: min-content min-content min-content;
  }
  
  /* date */
  ul li .date {
    --dateH: 3rem;
    height: var(--dateH);
    margin-inline: calc(var(--inlineP) * -1);
  
    text-align: center;
    background-color: var(--accent-color);
  
    color: white;
    font-size: 1rem;
    font-weight: 700;
  
    display: grid;
    place-content: center;
    position: relative;
  
    border-radius: calc(var(--dateH) / 2) 0 0 calc(var(--dateH) / 2);
  }

  ul li .date2 {
    --dateH: 3rem;
    height: var(--dateH);
    margin-inline: calc(var(--inlineP) * -1);
  
    text-align: center;
    background-color: var(--accent-color);
  
    color: white;
    font-size: 0.8rem;
    font-weight: 700;
  
    display: grid;
    place-content: center;
    position: relative;
  
    border-radius: calc(var(--dateH) / 2) 0 0 calc(var(--dateH) / 2);
  }

  ul li .date3 {
    --dateH: 3rem;
    height: var(--dateH);
    margin-inline: calc(var(--inlineP) * -1);
  
    text-align: center;
    background-color: var(--accent-color);
  
    color: white;
    font-size: 0.7rem;
    font-weight: 700;
  
    display: grid;
    place-content: center;
    position: relative;
  
    border-radius: calc(var(--dateH) / 2) 0 0 calc(var(--dateH) / 2);
  }

  ul li .date4 {
    --dateH: 3rem;
    height: var(--dateH);
    margin-inline: calc(var(--inlineP) * -1);
  
    text-align: center;
    background-color: var(--accent-color);
  
    color: white;
    font-size: 0.6rem;
    font-weight: 700;
  
    display: grid;
    place-content: center;
    position: relative;
  
    border-radius: calc(var(--dateH) / 2) 0 0 calc(var(--dateH) / 2);
  }
  
  /* title descr */
  ul li .title,
  ul li .descr {
    background: var(--bgColor);
    position: relative;
    padding-inline: 0rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    font-size: 1rem;
  }
  ul li .descr2 {
    background: var(--bgColor);
    position: relative;
    padding-inline: 0rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    font-size: 0.8rem;
  }
  ul li .descr3 {
    background: var(--bgColor);
    position: relative;
    padding-inline: 0rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    font-size: 0.7rem;
  }
  ul li .descr4 {
    background: var(--bgColor);
    position: relative;
    padding-inline: 0rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    font-size: 0.6rem;
    width: 15vh;
  }
  ul li .title {
    overflow: hidden;
    padding-block-start: 1.5rem;
    padding-block-end: 1rem;
    font-weight: 500;
  }
  ul li .descr {
    padding-block-end: 1.5rem;
    font-weight: 300;
  }
  ul li .title::before {
    bottom: calc(100% + 0.125rem);
  }
  
  ul li .descr::before {
    z-index: -1;
    bottom: 0.25rem;
  }
  
  @media (min-width: 40rem) {
    ul {
      grid-template-columns: 1fr var(--line-w) 1fr;
    }
    ul::before {
      grid-column: 2;
    }
    ul li:nth-child(odd) {
      grid-column: 1;
    }
    ul li:nth-child(even) {
      grid-column: 3;
    }
  
    /* start second card */
    ul li:nth-child(2) {
      grid-row: 2/4;
    }
  
    ul li:nth-child(odd) .date::before {
      clip-path: polygon(0 0, 100% 0, 100% 100%);
      left: 0;
    }
  
    ul li:nth-child(odd) .date::after {
      transform: translate(-50%, -50%);
      left: calc(100% + var(--col-gap) + var(--line-w) / 2);
    }
    ul li:nth-child(odd) .date {
      border-radius: 0 calc(var(--dateH) / 2) calc(var(--dateH) / 2) 0;
    }
  }

  .listone {
    --accent-color: #41516C;
  }
  .listtwo {
    --accent-color: #ef8545;
  }
  .listthree {
    --accent-color: #41516C;
  }
  .listfour {
    --accent-color: #ef8545;
  }
  .listfive {
    --accent-color: #41516C;
  }



  .wrapperDataVizOnePageTwo {
    height: 98vh;
    display: grid;
    place-items: center;
  }

  .wrapperDataVizOnePageTwo2 {
    height: 85vh;
    display: grid;
    place-items: center;
  }
  
  .typing-demo {
    width: 123ch;
    /* width: 50vw; */
    animation: typing 5s steps(123), blink .5s step-end infinite alternate;
    white-space: nowrap;
    overflow: hidden;
    border-right: 3px solid;
    font-family: monospace;
    font-size: 1.2rem;
  }

  .typing-demo2 {
    width: 45ch;
    /* width: 50vw; */
    animation: typing 2s steps(45), blink .5s step-end infinite alternate;
    white-space: nowrap;
    overflow: hidden;
    border-right: 3px solid;
    font-family: monospace;
    font-size: 1.2rem;
  }

  .typing-demo3 {
    width: 45ch;
    /* width: 50vw; */
    animation: typing 2s steps(45), blink .5s step-end infinite alternate;
    white-space: nowrap;
    overflow: hidden;
    border-right: 3px solid;
    font-family: monospace;
    font-size: 1rem;
  }

  .typing-demo4 {
    width: 45ch;
    /* width: 50vw; */
    animation: typing 2s steps(45), blink .5s step-end infinite alternate;
    white-space: nowrap;
    overflow: hidden;
    border-right: 3px solid;
    font-family: monospace;
    font-size: 0.8rem;
  }

  .typing-demo5 {
    width: 45ch;
    /* width: 50vw; */
    animation: typing 2s steps(45), blink .5s step-end infinite alternate;
    white-space: nowrap;
    overflow: hidden;
    border-right: 3px solid;
    font-family: monospace;
    font-size: 0.7rem;
  }
  
  @keyframes typing {
    from {
      width: 0
    }
  }
      
  @keyframes blink {
    50% {
      border-color: transparent
    }
  }


  i.down {
    border: solid #222;
    border-width: 0 0.4vw 0.4vw 0;
    display: inline-block;
    padding: 0.4vw;
    animation: upInArrow 1s forwards;
    animation-delay: 0.7s;
    opacity: 0;
  }
  
  .mainarrow {
    transition: 0.5s;
    top: 0;
    position: relative;
    animation: arrowMove 2s ease infinite;
    display: block;
  }

  a {
    text-decoration: none;
    color: inherit; }
  
  .cta {
    position: relative;
    margin: auto;
    padding: 19px 22px;
    transition: all .2s ease; }
    .cta:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      border-radius: 28px;
      background: #ef8545;
      width: 56px;
      height: 56px;
      transition: all .3s ease; }
    .cta span {
      position: relative;
      font-size: 16px;
      line-height: 18px;
      font-weight: 900;
      letter-spacing: .25em;
      text-transform: uppercase;
      vertical-align: middle; }
    .cta svg {
      position: relative;
      top: 0;
      margin-left: 10px;
      fill: none;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke: #111;
      stroke-width: 2;
      transform: translateX(-5px);
      transition: all .3s ease; }
    .cta:hover:before {
      width: 100%;
      background: #ffab9d; }
    .cta:hover svg {
      transform: translateX(0); }
    .cta:active {
      transform: scale(0.96); }



  /* .wrapper2 {
    width: 100vw;
    max-width: 100vw;
    margin: 0 auto;
    padding: 20px 0;
    font-size: 13px;
    line-height: 1.5;
    letter-spacing: 0.025em;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 400;
  } */
  .icon {
    width: 1em;
    height: 1em;
    stroke-width: 0;
  }
  .marketing.services-wrapper {
    width: 100vw;
    margin: 60px auto;
    padding: 0 !important;
    background-image: none;
    max-width: 700px;
  }
  .marketing.services-wrapper h3 {
    text-align: center;
    font-size: 28px;
    margin: 0 0 40px 0 !important;
    font-weight: 500;
  }
  .marketing.services-wrapper .marketing.service {
    height: auto;
    padding-bottom: 40px;
    width: 100%;
  }
  .floating-blurb {
    text-align: left !important;
    margin: 2% 0;
    padding: 10px 0 25px 15px !important;
    width: 90% !important;
    cursor: pointer;
    right: auto !important;
    top: auto !important;
  }
  .marketing.services-wrapper .floating-blurb h4 {
    padding: 0;
    line-height: 20px;
    font-size: 16px;
    color: #212121;
    text-transform: capitalize;
    font-weight: 700;
    letter-spacing: 0.025em;
    margin: 0 !important;
    text-align: left;
    width: 75%;
  }
  marketing services-wrapper .marketing.services-wrapper .floating-blurb p {
    padding: 5px 0 0;
    line-height: 30px;
    font-weight: 400;
    color: #555;
    font-size: 11px;
  }
  .marketing.services-wrapper .floating-blurb p.capital {
    font-size: 10px;
    text-transform: uppercase;
  }
  .marketing.services-wrapper .marketing.service svg.icon.marketing-icon {
    border-radius: 50%;
    padding: 5px;
    margin: -12px 0 0 -38px;
    fill: #fff;
    clear: both;
    font-size: 60px;
  }
  .marketing.services-wrapper svg.icon.marketing-icon {
    margin: 41px 0 13px 46.5%;
  }
  /*Borders*/
  .marketing.service.one {
    border-left: 4px solid #9da7b0;
  }
  .marketing.service.two {
    border-left: 4px solid #88939e;
  }
  .marketing.service.three {
    border-left: 4px solid #727f8c;
  }
  .marketing.service.four {
    border-left: 4px solid #5c6c7b;
  }
  .marketing.service.five {
    border-left: 4px solid #465869;
  }
  .marketing.service.six {
    border-left: 4px solid #314558;
  }
  /*SVG Backgrounds*/
  .marketing.service.one .marketing-icon-one svg.icon.marketing-icon.one {
    background: #9da7b0;
  }
  .marketing.service.two .marketing-icon-two svg.icon.marketing-icon.two {
    background: #88939e;
  }
  .marketing.service.three .marketing-icon-three svg.icon.marketing-icon.three {
    background: #727f8c;
  }
  .marketing.service.four .marketing-icon-four svg.icon.marketing-icon.four {
    background: #5c6c7b;
  }
  .marketing.service.five .marketing-icon-five svg.icon.marketing-icon.five {
    background: #465869;
  }
  .marketing.service.six .marketing-icon-six svg.icon.marketing-icon.last-icon {
    background: #314558;
  }